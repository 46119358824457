// Take the events, exatrct each even item numbers, and calculate estimate fee for event. Return the sum.
export const calculateFeeEstimate = (events) => {

	const mainArray: string[][] = []; // Initialize the main array

	// Iterate through each event
	events.forEach((event) => {
		let itemsArray = [];

		// Try to parse the 'actual_items' as a JSON array, fall back to an empty array if invalid
		try {
			itemsArray = JSON.parse(event.actual_items || '[]');
		} catch (error) {
			console.error("Error parsing actual_items:", error);
		}

		// Append the items array to the main array
		if (Array.isArray(itemsArray)) {
			mainArray.push(itemsArray);
		}
	});

	console.log("Main array of item numbers:", mainArray); // This will print the structure with subarrays of item numbers



	return mainArray.length ? true : false;
};