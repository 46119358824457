import React, { useState } from 'react'
import globalAxios from '../../globalAxios.js';
import { SERVER_URL } from "../../config.js";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';

import { useAppSelector, useAppDispatch } from '../../store/hooks.ts';
import { removeSession, deleteEventsByMonth } from '../../store/slices/calendarSlice.ts';

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Grid, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./Calendar.css";
import { calculateFeeEstimate } from './helper/feeCalculation.ts';
import { determineSessionFundingStatus } from './helper/fundingStatus.ts';

const Calendar = () => {
	const [open, setOpen] = useState(false);
	const [deleting, setDeleting] = useState(false); // for single event.
	const [deletingEvents, setDeletingEvents] = useState(false); // for current month of events.

	const [selectedEvent, setSelectedEvent] = useState({
		title: "",
		id: "",
		start: new Date(),
		surgeon: "",
		events: [],
	});

	const events = useAppSelector(state => state.calendar.events);
	const currentView = useAppSelector(state => state.calendar.view);
	const isAdmin = useAppSelector(state => state.user.admin)

	const dispatch = useAppDispatch();

	const handleEventClick = (clickInfo) => {
		const { title, id, start, extendedProps } = clickInfo.event;

		setSelectedEvent({
			title: title,
			id: id,
			start: start,
			surgeon: extendedProps.surgeon.first_name + " " + extendedProps.surgeon.last_name,
			events: extendedProps.events,
		});

		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedEvent({
			title: "",
			id: "",
			start: new Date(),
			surgeon: "",
			events: [],
		});
	};

	// const handleEdit = () => {
	// 	console.log('Editing event:', selectedEvent);
	// 	// Implement your edit logic here
	// 	setOpen(false);
	// };

	const handleDelete = async () => {
		console.log('Deleting event:', selectedEvent);

		if (!selectedEvent.id) {
			console.error('No event selected or missing event ID.');
			return;
		}

		setDeleting(true);

		try {
			// Replace `/api/sessions/${selectedEvent.id}` with your actual endpoint that handles session deletion
			const response = await globalAxios.delete(`${SERVER_URL}/user/sessions/${selectedEvent.id}`);

			if (response.status === 200) {
				console.log('Session deleted successfully.');
				dispatch(removeSession(parseInt(selectedEvent.id)));
			};

		} catch (error) {
			console.error('Error deleting session:', error);
		} finally {
			setDeleting(false);
			setOpen(false);
		}
	};


	const eventDidMount = (info) => {
		// Change the styling from bullet point to block.
		if (info.view.type === 'dayGridMonth') {
			const eventDate = info.event.startStr.split("T")[0];
			const eventsOnThisDay = document.querySelectorAll(
				`[data-date="${eventDate}"] .fc-event`
			);

			if (eventsOnThisDay.length > 3) {
				// Hide excess events
				for (let i = 3; i < eventsOnThisDay.length; i++) {
					eventsOnThisDay[i].style.display = 'none';
				}
			}
		}
	};




	// Styling display for individual events display on the current calendar view.
	const renderEventContent = (eventInfo) => {
		// Define checks.
		const isDayGridMonth = eventInfo.view.type === 'dayGridMonth';


		const abbreviatedHospital = abbreviateName(eventInfo.event.extendedProps.hospital.name);
		const abbreviatedSurgeon = abbreviateName(eventInfo.event.extendedProps.surgeon.first_name + " " + eventInfo.event.extendedProps.surgeon.last_name);

		// Convert month view time text to 24-hour format
		const formatMonthlyViewTime = (timeText) => {
			if (!timeText) return '';
			const timeRegex = /^(\d{1,2}):?(\d{2})?(a|p)$/;
			const match = timeText.match(timeRegex);
			if (match) {
				let [hours, minutes = '00', period] = [match[1], match[2], match[3]];
				hours = parseInt(hours, 10);
				if (period === 'p' && hours !== 12) {
					hours += 12;
				} else if (period === 'a' && hours === 12) {
					hours = 0;
				};
				return `${hours.toString().padStart(2, '0')}${minutes}`;
			};
			return timeText; // Return as-is if not matched
		};

		// Define the content to display conditionally
		const contentToDisplay = () => {

			switch (currentView) {
				case 'location':
					return {
						content: isDayGridMonth
							? `${formatMonthlyViewTime(eventInfo.timeText) ? formatMonthlyViewTime(eventInfo.timeText) + " - " : ""}${abbreviatedHospital} - ${abbreviatedSurgeon}`
							: `${eventInfo.timeText} ${eventInfo.event.title}`,
						backgroundColour: setEventColor(eventInfo.event.extendedProps.startingTime),
					};

				case 'uploadStatus':
					return {
						content: isDayGridMonth
							? `${abbreviatedHospital} - ${abbreviatedSurgeon}`
							: `${eventInfo.timeText} ${eventInfo.event.title}`,
						backgroundColour: '#388e3c',
					};

				case 'feeEstimate':
					const estimate = calculateFeeEstimate(eventInfo.event.extendedProps.events);

					return {
						content: estimate
							? `$1000.00, ${eventInfo.event.extendedProps.events?.length}x Pt. - ${abbreviatedSurgeon}`
							: `N/A, ${eventInfo.event.extendedProps.events?.length}x Pt. - ${abbreviatedSurgeon}`,
						backgroundColour: estimate ? '#388e3c' : 'grey',
					};

				case 'fundingStatus':
					const allCovered = determineSessionFundingStatus(eventInfo.event.extendedProps.events);

					return {
						content: allCovered
							? 'Funding Available'
							: 'Funding Missing',
						backgroundColour: allCovered ? '#388e3c' : 'grey',
					};

				default:
					return {
						content: `${abbreviatedHospital}`,
						backgroundColour: 'grey',
					};
			}
		};


		// Get the content and background colour from the contentToDisplay function
		const { content, backgroundColour } = contentToDisplay();

		return (
			<div
				style={{
					backgroundColor: backgroundColour, // Apply dynamic background colour
					color: 'white',
					padding: '2px 4px',
					width: '100%',
					borderRadius: isDayGridMonth ? '3px' : '0',
					display: isDayGridMonth ? 'block' : 'inline-block',
				}}
			>
				<div>{content}</div>
			</div>
		);
	};







	//  Used to determine current month to delete all monthly events.
	const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Track current month
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [isMonthlyView, setIsMonthlyView] = useState(true);
	// Update current month and view type
	const handleDatesSet = (dateInfo) => {
		setCurrentMonth(dateInfo.start.getMonth());
		setCurrentYear(dateInfo.start.getFullYear());
		setIsMonthlyView(dateInfo.view.type === 'dayGridMonth');
	};

	const handleDeleteEvents = async () => {
		setDeletingEvents(true);

		// Format the date as YYYY-MM
		const formattedDate = `${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}`;
		console.log(formattedDate)

		// axios request to delete all events within the current month 
		try {
			const response = await globalAxios.delete(`${SERVER_URL}/user/sessions`, {
				data: { date: formattedDate } // Pass the formatted date as data in the request body
			});
			if (response.status === 200) {
				console.log(response.data);
				// Dispatch the Redux action to update the state after deletion
				dispatch(deleteEventsByMonth(formattedDate));
			} else {
				console.error('Failed to delete events:', response.data.message);
			}
		} catch (e) {
			console.error('Error deleting events:', e);
		} finally {
			setDeletingEvents(false);
		};
	};

	return (
		<Box
			sx={{
				'& .fc': {
					maxHeight: "80vh",
					minHeight: "80vh",
				},
				'& .fc-event': {
					cursor: "pointer"
				}
			}}
		>
			<FullCalendar
				// fixedWeekCount={false}
				showNonCurrentDates={false}
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
				initialView="dayGridMonth"
				headerToolbar={{
					left: 'prev,next today',
					center: 'title',
					right: 'dayGridMonth,timeGridWeek'
				}}
				firstDay={1}
				editable={true}
				events={convertEventsFormat(events)}
				eventClick={handleEventClick}
				eventDidMount={eventDidMount} // Apply hiding logic after event is mounted
				datesSet={handleDatesSet} // Track the current month and view type
				viewDidMount={(viewInfo) => {
					if (viewInfo.view.type === 'dayGridMonth') {
						document.querySelectorAll('.fc-event').forEach((eventEl) => {
							eventEl.classList.add('fc-daygrid-event');
						});
					}
				}}
				eventContent={renderEventContent}
			/>

			<Grid container>
				{isAdmin && isMonthlyView ?
					<Grid item xs={2}>
						<Grid
							container
							spacing={2}
							justifyContent="center"
							alignItems="center"
							sx={{ marginTop: "1rem" }}
						>
							<Grid item>
								<Button
									variant="contained"
									color="error"
									size="small"
									disableElevation
									sx={{ color: "white" }}
									onClick={handleDeleteEvents}
									disabled={deletingEvents} // Button is disabled when loading is true
								>
									{deletingEvents ? <CircularProgress size={24} sx={{ color: "white" }} /> : 'Delete Events'}
								</Button>
							</Grid>
						</Grid>
					</Grid>
					:
					""
				}
			</Grid>


			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				PaperProps={{
					sx: {
						maxWidth: '80vw',  // Set max-width to 80vw
					}
				}}>
				<DialogTitle id="form-dialog-title">Session Details
					<Button
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}>
						Close
					</Button>
				</DialogTitle>
				<DialogContent>
					<Box>
						<p>{selectedEvent?.start ? new Date(selectedEvent.start).toLocaleDateString() : ''}</p>
						<p><b>{selectedEvent?.title}</b></p>
						<p><b>{selectedEvent?.surgeon}</b></p>
					</Box>
				</DialogContent>
				<DialogActions>
					{/* <Button onClick={handleEdit} color="primary">
						Edit
					</Button> */}
					{deleting ?
						<Button disabled>
							<svg width={0} height={0}>
								<defs>
									<linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
										<stop offset="0%" stopColor="#e01cd5" />
										<stop offset="100%" stopColor="#1CB5E0" />
									</linearGradient>
								</defs>
							</svg>
							<CircularProgress size={18} sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
						</Button> :
						<Button variant="contained" onClick={handleDelete} color="error" size="small" disableElevation>
							Delete Session
						</Button>
					}
				</DialogActions>
				<DialogContent>
					<Box>
						{selectedEvent?.events?.length > 0 ? (
							selectedEvent.events.map((event, index) => (
								<Accordion key={index}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography variant="body1"><b>Patient:</b> {event.patient.first_name} {event.patient.last_name} | <b>Procedure:</b> {event.procedure}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography variant="body2">
											DOB: {event.patient.date_of_birth}
										</Typography>
										<Typography variant="body2">
											Medicare: {event.patient.medicare}
										</Typography>
										<Typography variant="body2">
											Health Fund: {Array.isArray(event.patient.policies) && event.patient.policies.length > 0
												? event.patient.policies[0].healthFund.aliases[1]
												: 'N/A'}
										</Typography>
										<Typography variant="body2">
											Health Fund #: {Array.isArray(event.patient.policies) && event.patient.policies.length > 0
												? event.patient.policies[0].health_fund_no
												: 'N/A'}
										</Typography>
										<Typography variant="body2">
											Items:  {(() => {
												// Parse the string into an array
												let itemsArray = [];

												try {
													itemsArray = JSON.parse(event.actual_items);
												} catch (error) {
													console.error('Error parsing actual_items:', error);
												}

												return Array.isArray(itemsArray) && itemsArray.length > 0
													? itemsArray.join(', ')
													: '';
											})()}
										</Typography>
										<Typography variant="body2">
											Anaesthetist: {event.anaesthetist?.name || 'N/A'}
										</Typography>
										{/* Add more event details here if needed */}
									</AccordionDetails>
								</Accordion>
							))
						) : (
							<Typography variant="body2">No additional events available.</Typography>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default Calendar;


export const convertEventsFormat = (eventsArray) => {
	// console.log("========== Events =========== ", eventsArray.filter(e => e.date_of_service.includes("2024-05")))

	return eventsArray.map((event, _index) => {
		const startDate = event.date_of_service;
		const startTime = event.start_time ? `T${event.start_time}:00` : ''; // Add time only if start_time is not null

		return {
			id: event.id,
			title: toCapitalized(event.hospital.name),
			start: startDate + startTime,
			surgeon: {
				first_name: event.surgeon.first_name,
				last_name: event.surgeon.last_name,
			},
			hospital: {
				name: event.hospital.name
			},
			events: event.events,
			startingTime: event.start_time,
			// color: setEventColor(event.start_time),
		};
	});
};

const setEventColor = (startTime) => {
	if (startTime === null) {
		return "grey";
	};

	if (startTime < "11:30") {
		return "#1976d2";
	} else if (startTime > "11:30") {
		return "orange";
	} else {
		return "grey";
	};
};


const toCapitalized = (str) => {
	return str.split(' ').map(word =>
		word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
	).join(' ');
};

const abbreviateName = (name) => {
	return name.split(' ').map(word => word.charAt(0).toUpperCase()).join('');
};
