import React, { useState } from 'react';

import globalAxios from '../../globalAxios.js';

import { SERVER_URL } from "../../config.js";

import { useAppSelector, useAppDispatch } from '../../store/hooks.ts';
import { setStartDate, setEndDate } from '../../store/slices/calendarSlice.ts';
import { setSnackbarOpen, setSnackbarSeverity, setSnackbarText } from '../../store/slices/snackbarSlice.ts';

import { TextField, Button, CircularProgress, MenuItem, Box } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format, subDays, startOfWeek, subWeeks, endOfWeek, startOfMonth, endOfMonth, subMonths } from 'date-fns';
import { enAU } from 'date-fns/locale';
import saveAs from 'file-saver';

const ExcelGenerator = () => {

	const [fileName, setFileName] = useState('Template');

	const [presetValue, setPresetValue] = useState("last90Days");

	// Local state for loading indication
	const [loading, setLoading] = useState(false);

	const fields = useAppSelector(state => state.fields.fields)

	const startDateString = useAppSelector(state => state.calendar.startDate);
	const endDateString = useAppSelector(state => state.calendar.endDate);

	const dispatch = useAppDispatch();

	// Convert strings back to Date objects for the pickers
	const startDate = new Date(startDateString);
	const endDate = new Date(endDateString);

	// Handler for start date change
	const handleStartDateChange = (date: Date | null) => {
		if (date) {
			const startDate = new Date(date);
			startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00.000 UTC
			dispatch(setStartDate(startDate.toISOString()));
		}
	};

	// Handler for end date change
	const handleEndDateChange = (date: Date | null) => {
		if (date) {
			const endDate = new Date(date);
			endDate.setHours(23, 59, 59, 999);  // Set time to 23:59:59.999 UTC
			dispatch(setEndDate(endDate.toISOString()));
		}
	};

	// Handler for file name change
	const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFileName(event.target.value);
	};

	// HAndler for preset selection
	const handlePresetChange = (event) => {
		const value = event.target.value;
		setPresetValue(value);
		let start, end;

		end = new Date(); // 'To' defaults to today
		switch (value) {
			case "last7Days":
				start = subDays(end, 6);
				break;
			case "last30Days":
				start = subDays(end, 29);
				break;
			case "last60Days":
				start = subDays(end, 59);
				break;
			case "last90Days":
				start = subDays(end, 89);
				break;
			case "pastFullCalendarWeek":
				if (new Date().getDay() === 0) { // If today is Sunday
					// Start from last Monday (a week from today)
					start = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 2 });
					end = new Date(); // Today, which is Sunday
				} else {
					// Start from Monday of the current week, then subtract one week to go to previous Monday
					start = startOfWeek(new Date(), { weekStartsOn: 2 });
					start = subWeeks(start, 1);
					// End on Sunday of that week
					end = endOfWeek(start, { weekStartsOn: 1 });
				}
				break;
			case "pastFullCalendarMonth":
				if (end.getDate() !== endOfMonth(end).getDate()) { // If today is not the last day of the month
					end = endOfMonth(subMonths(end, 1)); // Last day of the previous month
				}
				start = startOfMonth(end);
				start.setDate(start.getDate() + 1);  // Adds one day to the start date
				break;
			case "pastFullCalendarYear":
				// Get today's date
				let today = new Date();
				// Determine the last day of the current month
				let lastDayOfCurrentMonth = endOfMonth(end);
				// Adjust the 'end' date to the last day of the previous month if today is not the last day of the current month
				if (today.getDate() !== lastDayOfCurrentMonth.getDate()) {
					end = endOfMonth(subMonths(today, 1)); // Last day of the previous month
				} else {
					end = today; // Today is the last day of the month, use today's date
				};
				// Set 'start' to the first day of the month 12 months before the calculated 'end'
				start = startOfMonth(subMonths(end, 12));
				start.setDate(start.getDate() + 1);  // Adds one day to the start date
				break;
			case "Q1":
				start = new Date(new Date().getFullYear(), 0, 2);  // January 1st
				end = new Date(new Date().getFullYear(), 2, 32);  // March 31st
				break;
			case "Q2":
				start = new Date(new Date().getFullYear(), 3, 2);  // April 1st
				end = new Date(new Date().getFullYear(), 5, 31);  // June 30th
				break;
			case "Q3":
				start = new Date(new Date().getFullYear(), 6, 2);  // July 1st
				end = new Date(new Date().getFullYear(), 8, 31);  // September 30th
				break;
			case "Q4":
				start = new Date(new Date().getFullYear(), 9, 2);  // October 1st
				end = new Date(new Date().getFullYear(), 11, 32);  // December 31st
				break;
			case "lastFinancialYear":
				// Calculate the start date as July 1st of two years ago
				start = new Date(new Date().getFullYear() - 2, 6, 2);
				// Calculate the end date as June 30th of last year
				end = new Date(new Date().getFullYear() - 1, 5, 31);
				break;
			default:
				start = subMonths(end, 12);
		};

		dispatch(setStartDate(start.toISOString().split('T')[0]));
		dispatch(setEndDate(end.toISOString().split('T')[0]));
	};

	// Handler for generate excel button click
	const handleGenerateExcel = async () => {
		setLoading(true);
		try {
			await globalAxios.post(
				`${SERVER_URL}/sessions/generate-excel`,
				{
					startDate: startDateString,
					endDate: endDateString,
					fields
				},
			).then(res => {
				const { base64Excel } = res.data;
				const timestamp = format(new Date(), 'dd-MM-yyyy__HH-mm');
				const fullFileName = `${fileName}____(exported ${format(new Date(startDateString), 'dd-MM-yyyy')} to ${format(new Date(endDateString), 'dd-MM-yyyy')})____(created ${timestamp}).xlsx`;

				// Decoding the base64 string to a blob
				const excelBlob = base64ToBlob(base64Excel, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
				if (excelBlob !== null) saveAs(excelBlob, fullFileName);
			}).then((res => {
				dispatch(setSnackbarOpen(true));
				dispatch(setSnackbarSeverity("success"));
				dispatch(setSnackbarText(`Converted all sessions from ${format(new Date(startDateString), 'dd/MM/yyyy')} to ${format(new Date(endDateString), 'dd/MM/yyyy')} successfully!`));
				setLoading(false);
			}));

			// Proceed with your Excel generation logic here
		} catch (error) {
			console.error('Error fetching sessions:', error.response ? error.response.data : error.message);
			dispatch(setSnackbarOpen(true));
			dispatch(setSnackbarSeverity("error"));
			dispatch(setSnackbarText("An error occurred: " + error.response ? error.response.data : error.message));
			setLoading(false);
		};
	};


	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 2, // Space between stacked elements
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%', // Make sure it takes the full width of the parent container
					padding: '1rem'
				}}
			>
				<TextField
					label="File Name"
					variant="outlined"
					size="small"
					value={fileName}
					onChange={handleFileNameChange}
					sx={{ width: '100%', minWidth: '100%' }}
				/>
				<TextField
					select
					label="Preset"
					size="small"
					value={presetValue}
					onChange={handlePresetChange}
					sx={{ width: '100%', minWidth: '100%' }}
				>
					<MenuItem value="last7Days">Last 7 Days</MenuItem>
					<MenuItem value="last30Days">Last 30 Days</MenuItem>
					<MenuItem value="last60Days">Last 60 Days</MenuItem>
					<MenuItem value="last90Days">Last 90 Days</MenuItem>
					<MenuItem value="pastFullCalendarWeek">Past Full Calendar Week</MenuItem>
					<MenuItem value="pastFullCalendarMonth">Past Full Calendar Month</MenuItem>
					<MenuItem value="pastFullCalendarYear">Past Full Calendar Year</MenuItem>
					<MenuItem value="Q1">Quarter 1 {`(Jan - Mar)`}</MenuItem>
					<MenuItem value="Q2">Quarter 2 {`(Apr - Jun)`}</MenuItem>
					<MenuItem value="Q3">Quarter 3 {`(Jul - Sep)`}</MenuItem>
					<MenuItem value="Q4">Quarter 4 {`(Oct - Dec)`}</MenuItem>
					<MenuItem value="lastFinancialYear">Last Financial Year</MenuItem>
				</TextField>
				<DesktopDatePicker
					label="From"
					inputFormat="dd/MM/yyyy"
					maxDate={endDate}
					value={startDate}
					onChange={handleStartDateChange}
					sx={{ minWidth: "100%" }}
					slotProps={{ textField: { size: 'small' } }}
					renderInput={(params) => <TextField {...params} fullWidth size="small" />}
				/>
				<DesktopDatePicker
					label="To"
					inputFormat="dd/MM/yyyy"
					minDate={startDate}
					value={endDate}
					onChange={handleEndDateChange}
					sx={{ minWidth: "100%" }}
					slotProps={{ textField: { size: 'small' } }}
					renderInput={(params) => <TextField {...params} fullWidth size="small" />}
				/>
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={handleGenerateExcel}
					disabled={loading}
					disableElevation
					sx={{ width: '100%', minWidth: '100%' }}
				>
					{loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : 'Generate Excel'}
				</Button>
			</Box>
		</LocalizationProvider>
	);
};

export default ExcelGenerator;


const base64ToBlob = (base64: string, contentType: string): Blob => {
	const byteCharacters = atob(base64);
	const byteArrays: Uint8Array[] = [];

	for (let offset = 0; offset < byteCharacters.length; offset += 512) {
		const slice = byteCharacters.slice(offset, offset + 512);

		const byteNumbers: number[] = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, { type: contentType });
};