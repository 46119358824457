import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Define the slice state for settings
interface SettingsState {
	activeTab: number;
	aliasesTab: number;
}

const initialState: SettingsState = {
	activeTab: 0, // Default to the first tab
	aliasesTab: 0,
};

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setActiveTab: (state, action: PayloadAction<number>) => {
			state.activeTab = action.payload;
		},
		setAliasesTab: (state, action: PayloadAction<number>) => {
			state.aliasesTab = action.payload;
		},
	},
});

export const { setActiveTab, setAliasesTab } = settingsSlice.actions;

// Selector for getting activeTab value
export const selectActiveTab = (state: RootState) => state.settings.activeTab;
export const selectAliasesTab = (state: RootState) => state.settings.aliasesTab;

export default settingsSlice.reducer;



// Define the alias array
export const aliasMapping = ['Hospital', 'Health Fund', 'Surgeon', 'Other'];
