// User slice for managing user details
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface UserState {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	admin: boolean | null;
	settings: Record<string, any> | null;  // Define settings as a flexible JSON object
}

// Define the initial state using that type
const initialState: UserState = {
	id: 0,
	firstName: "",
	lastName: "",
	email: "",
	admin: null,
	settings: null,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		// Action to set the user details
		setUser: (state, action: PayloadAction<UserState>) => {
			state.id = action.payload.id;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.email = action.payload.email;
			state.admin = action.payload.admin;
			state.settings = action.payload.settings;
		},
		updateCustomPresets: (state, action: PayloadAction<{ name: string, fields: string[] }>) => {
			if (!state.settings) {
				state.settings = { customPresets: {} };
			} else if (!state.settings.customPresets) {
				state.settings.customPresets = {};
			}
			state.settings.customPresets[action.payload.name] = action.payload.fields;
		},
		deleteCustomPreset: (state, action: PayloadAction<string>) => {
			if (state.settings && state.settings.customPresets) {
				delete state.settings.customPresets[action.payload];
			}
		},
		updateDefaultPreset: (state, action: PayloadAction<{ name: string, fields: string[] }>) => {
			if (!state.settings) {
				state.settings = { defaultPreset: {} };
			} else if (!state.settings.defaultPreset) {
				state.settings.defaultPreset = {};
			}
			state.settings.defaultPreset.name = action.payload.name;
			state.settings.defaultPreset.fields = action.payload.fields;
		},
	}
});

export const { setUser, updateCustomPresets, deleteCustomPreset, updateDefaultPreset } = userSlice.actions;

// Selector to get user state
export const selectUserState = (state: RootState) => state.user;

export default userSlice.reducer;
