import React from 'react';
import { Box, Tabs, Tab, Typography, Paper } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks.ts';
import { aliasMapping, setAliasesTab, selectAliasesTab } from '../../../store/slices/settingsSlice.ts';

const Aliases = () => {
	const dispatch = useAppDispatch();
	const aliasesTab = useAppSelector(selectAliasesTab); // Get the current aliases sub-tab from Redux

	const handleSubTabChange = (event, newValue) => {
		dispatch(setAliasesTab(newValue)); // Update the Redux state when the sub-tab changes
	};

	// Render content based on the selected sub-tab
	const renderSubTabContent = () => {
		switch (aliasesTab) {
			case 0:
				return <Typography variant="body1">Hospital Settings</Typography>;
			case 1:
				return <Typography variant="body1">Healthfund Settings</Typography>;
			case 2:
				return <Typography variant="body1">Surgeon Settings</Typography>;
			case 3:
				return <Typography variant="body1">Other Settings</Typography>;
			default:
				return null;
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			{/* Sub Tab header */}
			<Box sx={{ borderColor: 'divider', marginBottom: '1rem' }}>
				<Tabs
					value={aliasesTab}
					onChange={handleSubTabChange}
					aria-label="Aliases Sub Tabs"
					sx={{ minHeight: "2.5rem", maxHeight: "2.5rem" }}
				>
					{aliasMapping.map((alias, index) => (
						<Tab
							key={index}
							sx={{ minHeight: "2.5rem", maxHeight: "2.5rem" }}
							label={alias} // Capitalize first letter of each alias
						/>
					))}
				</Tabs>
			</Box>

			{/* Sub Tab content */}
			<Box sx={{ flexGrow: 1 }}>
				<Paper elevation={2} sx={{ padding: 2 }}>
					{renderSubTabContent()} {/* Render the content based on the selected sub-tab */}
				</Paper>
			</Box>
		</Box>
	);
};

export default Aliases;
