// components/LensView.tsx

import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from '../../../store/hooks.ts'; // Assuming you have this set up
import { setCalendarView } from '../../../store/slices/calendarSlice.ts';

const LensView = () => {

	const dispatch = useAppDispatch(); // Initialize dispatch
	const currentView = useAppSelector(state => state.calendar.view);

	const handleViewChange = (view: string) => {
		dispatch(setCalendarView(view)); // Dispatch the action with the view type
	};

	// Define a function to check if the button is selected
	const isSelected = (view: string) => currentView === view;

	// List of buttons with view types and labels
	const viewButtons = [
		{ view: 'location', label: 'Location' },
		{ view: 'uploadStatus', label: 'Upload Status' },
		{ view: 'feeEstimate', label: 'Fee Estimate' },
		{ view: 'fundingStatus', label: 'Funding Status' }
	];

	return (
		<Box>
			<Typography variant="body1" gutterBottom fontWeight={600} textAlign="center">
				View
			</Typography>
			{/* Dynamically create buttons */}
			{viewButtons.map(button => (
				<Button
					key={button.view} // Unique key for each button
					disableElevation
					disableRipple
					variant="contained"
					color="primary"
					size="small"
					fullWidth
					onClick={() => handleViewChange(button.view)} // Dispatch the view change
					sx={{
						marginBottom: "0.5rem",
						backgroundColor: `${isSelected(button.view) ? "#34859E" : "primary"}` // Conditionally style button
					}}
				>
					{button.label}
				</Button>
			))}
		</Box>
	);
};

export default LensView;
