import createTheme from "@mui/material/styles/createTheme";

const theme = createTheme({
	palette: {
		primary: {
			main: "#78c4dc",
			contrastText: "white",
		},
	},
	typography: {
		allVariants: {
			color: "#1a262a"
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
                html {
                    font-size: 1rem; /* Default font size for large devices */
                }
                @media (max-width: 1366px) {
                    html {
                        font-size: 0.8rem; /* Medium devices */
                    }
                }
            `,
		},
	},
});

export default theme;