import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";

import globalAxios from '../../../globalAxios.js';

import { SERVER_URL } from "../../../config.js";

import { useAppSelector } from '../../../store/hooks.ts'; // Assuming you have this set up
import { aliasMapping, selectAliasesTab } from '../../../store/slices/settingsSlice.ts';

const Search = ({ miniNavRef }) => {
	// State to hold the search input
	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(false);

	const [searchResults, setSearchResults] = useState([]);
	const [error, setError] = useState('');

	const aliasesTab = useAppSelector(selectAliasesTab);
	// Get the alias name dynamically based on the current tab index
	const aliasType = aliasMapping[aliasesTab] || 'unknown';

	const resultsRef = useRef(null); // Reference for the search heading
	const listRef = useRef(null);
	const [containerHeight, setContainerHeight] = useState('auto');
	const [shouldScroll, setShouldScroll] = useState(false);

	// Dynamically set the container height based on available space
	useEffect(() => {
		const updateContainerHeight = () => {
			let resultsBottom = 0;
			let miniNavBottom = window.innerHeight; // Default in case ref is unavailable

			// Get the bottom of the miniNav container if the ref exists
			if (miniNavRef.current) {
				const miniNavRect = miniNavRef.current.getBoundingClientRect();
				miniNavBottom = miniNavRect.bottom;
			}

			// Get the bottom of the "results" text
			if (resultsRef.current) {
				const { bottom } = resultsRef.current.getBoundingClientRect();
				resultsBottom = bottom;
			}

			// Calculate the height between the bottom of the "results" and the bottom of the miniNav
			const availableHeight = miniNavBottom - resultsBottom - 20; // Subtract some padding if needed
			setContainerHeight(`${availableHeight}px`);

			// Determine if scrolling is needed
			if (listRef.current && listRef.current.scrollHeight > availableHeight) {
				setShouldScroll(true);
			} else {
				setShouldScroll(false);
			}
		};

		updateContainerHeight();
		window.addEventListener('resize', updateContainerHeight);
		return () => window.removeEventListener('resize', updateContainerHeight);
	}, [miniNavRef, searchResults]);

	// Handler to update the search term when the input changes
	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleSearch = async () => {
		if (!searchTerm) {
			setError("Please enter a search term");
			return;
		}

		if (aliasMapping[aliasesTab] === "Other") {
			setError("Please enter a search term");
			return;
		};

		setError('');
		setLoading(true);

		try {
			const response = await globalAxios.post(
				`${SERVER_URL}/search`,  // Adjust the URL to your backend search endpoint
				{
					searchTerm, // The search term entered by the user
					aliasType,  // The current alias tab (hospital, healthfund, etc.)
				}
			);
			console.log("Search Results:", response.data.results); // Process the search results
			setSearchResults(response.data.results);

			// Do something with the response, such as displaying the results or saving them in state
		} catch (error) {
			console.error("Error performing search:", error);
			setError("There was an error performing the search. Please try again.");
		} finally {
			// Stop loading
			setLoading(false);
		}
	};


	return (
		<Box>
			<Typography variant="body1" gutterBottom fontWeight={600} textAlign="center">
				Search {aliasType}
			</Typography>

			{/* Search bar */}
			<TextField
				label="Search"
				variant="outlined"
				size="small"
				value={searchTerm}
				onChange={handleSearchChange}
				sx={{ width: '100%', minWidth: '100%' }}
				margin="normal"
			/>
			{/* Optional Button to trigger the search */}
			<Button
				disableElevation
				disableRipple
				variant="contained"
				color="primary"
				sx={{ width: '100%', minWidth: '100%' }}
				onClick={handleSearch}
			>
				{loading ? 'Searching...' : 'Search'}
			</Button>

			{/* Show the number of matched results */}
			{!loading && searchResults.length > 0 && (
				<Typography ref={resultsRef} variant="body2" color="textSecondary" gutterBottom>
					{searchResults.length} {searchResults.length === 1 ? 'result' : 'results'} found
				</Typography>
			)}

			{/* Scrollable search results */}
			<Box
				ref={listRef}
				sx={{
					maxHeight: containerHeight,
					overflowY: shouldScroll ? 'scroll' : 'visible', // Enable vertical scrolling
					marginBottom: '1rem', // Add some space below the results
				}}
			>
				<List>
					{searchResults.map((result, index) => (
						<ListItem key={index} divider>
							<ListItemText
								primary={result.name || result.title} // Adjust based on result data structure
								secondary={`ID: ${result.id}`} // Adjust as necessary
							/>
						</ListItem>
					))}
				</List>
			</Box>

			{/* Display message if no results */}
			{searchResults.length === 0 && !loading && !error && (
				<Typography variant="body2" color="textSecondary">
					No results found.
				</Typography>
			)}
		</Box>
	);
};

export default Search;
